export const UsuarioEntity = {
    id: null,
    username: '',
    email: '',
    password: '',
    nombre: '',
    apellido: '',
    docIdentidad: '',
    nroTelefono: '',
    estado: true,
    role: '',
    enabled: true
};
